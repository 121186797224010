import { ReactNode } from 'react';

import ReactModal from 'react-modal';
import { useModal } from 'react-modal-hook';

import Link from 'components/link/link';

import './styles/hr-toolkit.scss';
import CloseBtn from '../buttons/button-close';

ReactModal.setAppElement('#___gatsby');


type ModalHrToolkitProps = {
  buttonUrl: string,
  children: ReactNode,
  copy: string,
  previewImage: string,
  title: string
}

const ModalHrToolkit = ({ 
  children,
  previewImage,
  title,
  buttonUrl,
  copy,
}:ModalHrToolkitProps) => {

  const [showModal, hideModal] = useModal(({ onExited }) => (
    <ReactModal
      isOpen
      onAfterClose={ onExited }
      onRequestClose={ hideModal }
      className="default-modal-wrapper hr-toolkit-modal"
      overlayClassName="default-modal-overlay"
    >
      <section data-section="ModalHrToolkit" className="hr-toolkit-modal-container">
        <CloseBtn onClick={ hideModal } />
        <div className="columns">
          <div className="column is-half">
            <img loading="lazy" src={ previewImage } alt="fill me out" />
          </div>
          <div className="column is-half is-centered">
            <h3 className="title">{title}</h3>
            <p>{copy}</p>
            <div className="field has-addons">
              <p className="control">
                <a
                  href={ buttonUrl }
                  className="button resource-download"
                  download={ buttonUrl }
                  target="_blank" rel="noreferrer"
                >
                  <span>Download</span>
                </a>
              </p>
              <p className="control">
                <Link
                  to="#wheniworkNewsletter"
                  className="button"
                  onClick={ hideModal }
                >
                  <span>Download Full Toolkit</span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </ReactModal>
  ));

  return (
    <button type="button" className="card" onClick={ showModal }>
      {children}
    </button>
  );
};

export default ModalHrToolkit;
