import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import ACFImage from 'types/ACFImage';

import Vero from 'components/forms/vero';
import Yoast from 'components/globals/yoast';
import { HeroDefaultPage } from 'components/hero/default-page';
import ModalHrToolkit from 'components/modal/hr-toolkit';
import Tabs from 'components/tabs/tabs';

import './styles/hr.scss';

type Tool = {
  copy: string;
  download_resource: {
    url: ACFImage
  };
  excerpt: string;
  icon: {
    url: ACFImage;
  };
  preview_image: ACFImage;
  title: string;
};

export default function Hr() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "hr" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              default_page_hero {
                background_image {
                  source_url
                }
                title
                sub_title
              }
              hr_toolkit {
                topic {
                  topic_title
                  resource {
                    title
                    excerpt
                    copy
                    download_resource {
                      title
                      url {
                        source_url
                      }
                    }
                    icon {
                      url {
                        source_url
                      }
                    }
                    preview_image {
                      source_url
                      alt_text
                    }
                  }
                }
              }
              download_content {
                content_title
                submit_button_text
                download_url {
                  source_url
                }
              }
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const yoastData = data.allWordpressPage.edges[0].node.yoast_head_json;
  const heroContent = pageContext.default_page_hero;

  const label = pageContext.hr_toolkit.topic.map(tab => tab.topic_title);

  return (
    <Layout showModalButton showInitFields>
      <Yoast { ...yoastData } />
      <div className="hr-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroDefaultPage { ...heroContent } imageFillBackground />
        <section className="toolKit-section section" data-section="hrToolkit">
          <div className="container">
            <div className="columns">
              <Tabs label={ label }>
                {pageContext.hr_toolkit.topic.map((resources, index) => (
                  <div key={ index } className="review-tab">
                    <div className="columns is-multiline">
                      {resources.resource.map((tool: Tool, index) => {
                        return (
                          <div
                            key={ index }
                            className="column is-one-third is-flex"
                          >
                            <ModalHrToolkit
                              title={ tool.title }
                              copy={ tool.copy }
                              previewImage={ tool.preview_image.source_url }
                              buttonUrl={ tool.download_resource.url.source_url }
                            >
                              <div className="card-content has-text-left">
                                <h3 className="card-title title has-text-left">
                                  {tool.title}
                                  <img
                                    className="card-icon"
                                    src={ tool.icon.url.source_url }
                                  />
                                </h3>
                                <p className="copy">{tool.excerpt}</p>
                              </div>
                            </ModalHrToolkit>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ))}
              </Tabs>
            </div>
          </div>
        </section>
        <section
          id="wheniworkNewsletter"
          className="section newsletter"
          data-section="hr newsletter section"
        >
          <div className="container">
            <h2 className="title has-text-centered">
              Download The Entire Toolkit In 1-Click
            </h2>
            <p className="copy has-text-centered">
              Want access to all these HR documents at once? Save time by
              filling out the form below to download the HR toolkit swipe file.
            </p>
            <div className="columns is-centered">
              <div className="column is-half is">
                <Vero
                  formType="contentDownload"
                  fullNameField
                  emailField
                  industryField
                  employeesField
                  contentTitle={ pageContext.download_content.content_title }
                  downloadUrl={
                    pageContext.download_content.download_url.source_url
                  }
                  submitBtnText={
                    pageContext.download_content.submit_button_text
                  }
                  uniqueId="-vero-ebook"
                  showIcons
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
